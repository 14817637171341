
import Swiper from 'swiper';
import { Mousewheel, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';

const sliderGalleryInit = ()=> {

    const swiperGallery = new Swiper('.gallery__swiper', {
        modules: [Mousewheel, Scrollbar],
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            576: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1200: {
                slidesPerView: 3,
            },
        },
        mousewheel: {
            enabled: true,
            forceToAxis: true,
            // sensitivity: 4,
        },
        // touchMoveOpposite: function () {
        //     console.log('touchMove');
        // },
        scrollbar: {
            el: '.gallery__swiper_scrollbar',
            draggable: true,
        },
    });
}

export default sliderGalleryInit;
