import axios from "axios";

const formInit = ()=> {


    // console.log('formInit');

    const inputElms = document.querySelectorAll('.form__input');

    inputElms.forEach(inputElm => {

        if (inputElm.value !== '') {
            inputElm.parentNode.classList.add('has-value');
        }

        inputElm.addEventListener('focus', () => {
            inputElm.parentNode.classList.add('has-value');
        });

        inputElm.addEventListener('blur', () => {
            // console.log(inputElm.value === '');
            if (inputElm.value === '') {
                inputElm.parentNode.classList.remove('has-value');
            }
        });
    });


    const formContactInit = () => {

        const hideErrors = () => {
            const formColElms = document.querySelectorAll('.form__col');
            formColElms.forEach(formColElm => {
                formColElm.classList.remove('has-error', 'has-value');
                const errorElm = formColElm.querySelector('.form__col_error');
                if (errorElm) {
                    errorElm.remove();
                }
            });
        }

        const formElm = document.querySelector('.form__block');
        formElm.addEventListener('submit', (e) => {
            e.preventDefault();
            axios
                .post('/send-email', {
                    name: document.querySelector('[name="name"]').value,
                    email: document.querySelector('[name="email"]').value,
                    message: document.querySelector('[name="message"]').value,
                })
                .catch(function (error) {
                    if (error.response) {

                        hideErrors();

                        const errors = error.response.data.errors;
                        for (const [key, value] of Object.entries(errors)) {
                            const errorElm = document.createElement('div');
                            const parentElm = document.querySelector(`[name="${key}"]`).parentNode;
                            parentElm.classList.add('has-error');
                            errorElm.classList.add('form__col_error');
                            errorElm.innerHTML = value;
                            parentElm.appendChild(errorElm);
                        }
                    }
                })
                .then((response) => {
                    if (response) {
                        hideErrors();
                        formElm.reset();
                    }
                })
        });

    }

    formContactInit();

    // return {
    //     formContactInit
    // }

}

export default formInit;
