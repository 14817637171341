
const bodyInit = ()=> {

    const bodyElm = document.querySelector('body');

    const hideBodyOverflow = () => {
        const getScrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        bodyElm.style.paddingRight = getScrollbarWidth + 'px';
        bodyElm.classList.add('overflow-hidden');
    }

    const showBodyOverflow = () => {
        bodyElm.style.paddingRight = 0;
        bodyElm.classList.remove('overflow-hidden');
    }

    return { hideBodyOverflow, showBodyOverflow };
}

export default bodyInit;
