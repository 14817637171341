import bodyInit from './body.js';

const videoInit = ()=> {

    const trailerBlockElm = document.querySelector('.trailer__video');
    const buttonPlayVideoElm = document.querySelector('.video__play_trigger');
    const buttonCloseVideoElm = document.querySelector('.video__close_trigger');
    const videoElm = document.querySelector('.trailer__video video');

    const  { hideBodyOverflow, showBodyOverflow } = bodyInit();

    const videoOpenHandle = () => {
        const openButtonElm = document.querySelector('.open__button');
        openButtonElm.addEventListener('click', (e) => {
            e.preventDefault(); e.stopPropagation();

            trailerBlockElm.classList.add('open');
            videoElm.play();
            openButtonElm.blur();

            hideBodyOverflow();
        });
    }

    const  videoPlayPause = () => {
        const defaultTitle = buttonPlayVideoElm.getAttribute('data-title');
        const activeTitle = buttonPlayVideoElm.getAttribute('data-title-active');

        if (buttonPlayVideoElm.classList.contains('pause')) {
            buttonPlayVideoElm.classList.remove('pause')
            buttonPlayVideoElm.setAttribute('title', defaultTitle);
            videoElm.pause();
        } else {
            buttonPlayVideoElm.classList.add('pause')
            videoElm.play();
            buttonPlayVideoElm.setAttribute('title', activeTitle);
        }
    }

    const videoControls = () => {

        buttonPlayVideoElm.addEventListener('click', (e) => {
            e.preventDefault(); e.stopPropagation();
            videoPlayPause();
        });

        buttonCloseVideoElm.addEventListener('click', (e) => {
            e.preventDefault(); e.stopPropagation();
            trailerBlockElm.classList.remove('open');
            videoElm.pause();
            showBodyOverflow();
        });
    }

    document.addEventListener("keydown", function(e) {
        if (e.keyCode === 27 || e.keyCode === 'esc') {
            showBodyOverflow();
            trailerBlockElm.classList.remove('open');
            videoElm.pause();
        }
        if (e.keyCode === 32  && trailerBlockElm.classList.contains('open')) {
            videoPlayPause();
        }
    });

    videoOpenHandle();
    videoControls();
}

export default videoInit;
