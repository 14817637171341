import GLightbox from 'glightbox';
import sliderGalleryInit from "./components/sliders";
import menuInit from "./components/menu";
import videoInit from "./components/video";
import formInit from "./components/form";

document.addEventListener("DOMContentLoaded", () => {

    // init Sliders
    sliderGalleryInit();

    // init Menu
    menuInit();

    // init Video
    videoInit();

    // init Lightbox
    const lightbox = GLightbox({
        selector: '.image__gallery_item',
    });


    formInit()


});
